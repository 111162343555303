import { nodeInstance } from "@/plugins/axios";

const groupAnnouncementServices = {};

groupAnnouncementServices.applyForResearchGroup = obj => {
    return nodeInstance.post(`/api/groupAnnouncement`, obj);
};

groupAnnouncementServices.getParticipatingGroupsAccepted = (id) => {
    return nodeInstance.get(`/api/groupAnnouncement/accepted/${id}`);
}

groupAnnouncementServices.getSpecificGroupAnnouncement = (id) => {
    return nodeInstance.get(`/api/groupAnnouncement/${id}`);
}

groupAnnouncementServices.createExternalResearchGroup = obj => {
    return nodeInstance.post(`/api/groupExternal`, obj);
};

groupAnnouncementServices.applyForExternalResearchGroup = obj => {
    return nodeInstance.post(`/api/groupAnnouncement/external`, obj);
};
groupAnnouncementServices.updateGroupAnnouncement = (obj, id) => {
    return nodeInstance.patch(`/api/groupAnnouncement/${id}`, obj);
}

groupAnnouncementServices.deleteGroupPostulation = (id) => {
    return nodeInstance.delete(`/api/groupAnnouncement/${id}`);
}

export default groupAnnouncementServices;