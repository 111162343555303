<template src="./DetailAnnouncement.html"></template>

<script>
import announcementService from "../../services/Announcement";
import groupAnnouncementServices from "../../services/GroupAnnouncement";

export default {
  name: "detail-announcement",
  data() {
    return {
      announcement: {
        announcement: {
          name: "",
          institution: { name: "" },
          description: "",
          category: { name: "" },
          dateInit: "",
          dateFinish: "",
        },
        groupAnnouncements: [{ group: { name: "" } }],
      },
      postulatedGroupsAccepted: [],
      qualifyingPostulates: false,
      showViewMore: false,
      modalWinnerAnnouncement: false,
      searchWinner: "",
      modalDeleteAnnouncement: false,
      groupAnnouncements: [],
    };
  },
  created() {
    this.getSpecificAnnouncement();
  },
  methods: {
    showModalWinner() {
      this.modalWinnerAnnouncement = true;
    },
    async deleteAnnouncement() {
      try {
        this.modalDeleteAnnouncement = true;
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: blogArticles/deleteAnnouncement()", e);
      }
    },
    async removeAnnouncement() {
      try {
        await announcementService.deleteAnnouncement(this.$route.params.id);
        this.modalDeleteAnnouncement = false;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Convocatoria eliminada",
          position: "is-bottom",
          type: "is-success",
        });
        this.$router.push({
          name: "ViewAllAnnouncementOpen",
        });
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: blogArticles/removeAnnouncement()", e);
      }
    },
    async getSpecificAnnouncement() {
      try {
        this.announcement = await announcementService.getParticipatingGroups(
          this.$route.params.id
        );
        this.groupAnnouncements = this.announcement.groupAnnouncements;
        if (this.announcement.announcement.evaluation) {
          this.qualifyingPostulates = true;
        }
        this.postulatedGroupsAccepted =
          await groupAnnouncementServices.getParticipatingGroupsAccepted(
            this.$route.params.id
          );
      } catch (e) {
        console.error("error", e);
      }
    },
    async chooseAsWinner() {
      try {
        const paramsWinner = {
          winner: this.searchWinner,
        };
        await announcementService.updateAnnouncement(
          this.$route.params.id,
          paramsWinner
        );
        this.$buefy.toast.open({
          duration: 5000,
          message: "Ganador seleccionado correctamente",
          position: "is-bottom",
          type: "is-success",
        });
        this.$router.push({
          name: "DetailAnnouncementClosed",
          params: { id: this.$route.params.id },
        });
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("error", error);
      }
    },
    async filterAccepted() {
      try {
        const obj = {
          announcementId: this.$route.params.id,
          stateGroupAnnouncement: true,
        };
        const infoGroupAnnouncements =
          await announcementService.groupAnnouncementByState(obj);
        this.groupAnnouncements = infoGroupAnnouncements.groupAnnouncements;
      } catch (error) {
        console.error("error", error);
      }
    },
    async filterRejected() {
      try {
        const obj = {
          announcementId: this.$route.params.id,
          stateGroupAnnouncement: false,
        };
        const infoGroupAnnouncements =
          await announcementService.groupAnnouncementByState(obj);
        this.groupAnnouncements = infoGroupAnnouncements.groupAnnouncements;
      } catch (error) {
        console.error("error", error);
      }
    },
    async filterInEvaluation() {
      try {
        const obj = {
          announcementId: this.$route.params.id,
          stateGroupAnnouncement: null,
        };
        const infoGroupAnnouncements =
          await announcementService.groupAnnouncementByState(obj);
        this.groupAnnouncements = infoGroupAnnouncements.groupAnnouncements;
      } catch (error) {
        console.error("error", error);
      }
    },
  },
  computed: {
    colorByStatus() {
      return (status) => {
        switch (status) {
          case false:
            return "dotRed";
          case true:
            return "dotGreen";
          default:
            null;
            return "dotYellow";
        }
      };
    },
    nameByStatus() {
      return (status) => {
        switch (status) {
          case false:
            return "Rechazado";
          case true:
            return "Aceptado";
          default:
            null;
            return "En evaluación";
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped src="./DetailAnnouncement.scss"></style>